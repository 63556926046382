<template>
  <div>
    <a-popup :visible="visible" @cancel="onCancel">
      <div
        class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px]  tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
        <div>
          <div
            class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold dark:tw-text-gray-700 tw-text-gray-500 dark:tw-bg-gray-100 tw-bg-gray-50">
            <span>{{ $t("fields.orders_with_errors_count") }}:</span>
            <icon v-if="loading" class="tw-text-rose-500 tw-text-lg" icon="eos-icons:three-dots-loading" />
            <span v-else class="tw-text-rose-500">{{ orders.length }}</span>
          </div>
          <div v-if="loading" class="tw-p-5 tw-bg-white tw-flex-1"> 
            <p class="tw-p-5 tw-text-center">
              {{ $t("common.loading") }}
            </p>
          </div>
          <div v-if="!loading" class="tw-p-5 tw-bg-white tw-flex-1"> 
            <div class="tw-grid tw-grid-cols-3 tw-gap-2">
              <div v-for="order in orders" :key="order.google_sheet_order_id" class="tw-p-1 tw-border tw-border-solid tw-rounded-sm tw-shadow-sm">
                <div class="tw-flex tw-items-center tw-gap-1">
                  <p>Order ID: </p>
                  <span class="tw-font-semibold tw-text-emerald-500">{{ order.google_sheet_order_id }}</span>
                </div>

                <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-2">
                  <div class="tw-bg-gray-100 tw-px-1 tw-border tw-border-solid tw-border-gray-200 tw-rounded-sm tw-text-sm" v-for="e in order.errors" :key="e">{{ e }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-popup>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';
import { refresh } from "@/api/sheets";

const loading = ref(false)
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: Boolean,
  sheet: Object
})

const orders = ref([])

const visible = computed(() => props.visible)

const onCancel = () => {
  emit('update:visible', false)
}

const getOrdersWithErrors = async () => {
  loading.value = true
  await refresh(props.sheet.id)
    .then(
      res => {
        if (res.data.code == 'SUCCESS') {
          orders.value = res.data.results.original.data.orders_with_errors;
          
        }
      },
      err => {
        console.log(err);
      }
    )
  loading.value = false
}

getOrdersWithErrors();

</script>

<style></style>