<template>
    <a-button @click="visible = true" class="tw-hidden md:tw-flex" left-icon="heroicons:plus-16-solid">{{
            $t("buttons.create") }}</a-button>
        <a-button @click="visible = true" class="md:tw-hidden" icon="heroicons:plus-16-solid"></a-button>
    <a-popup :visible="visible" @cancel="onCancel">
        <div
            class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px]  tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
            <div>
                <div
                    class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold dark:tw-text-gray-700 tw-text-gray-500 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <span>{{ $t("common.sourcing_request") }}</span>
                </div>

                <div class="tw-p-5 tw-bg-white tw-flex-1">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">


                        <div class="tw-col-span-12 md:tw-col-span-12 tw-pb-4">
                            <h1 class="tw-ps-4 tw-border-s-2 tw-border-solid tw-border-primary-500">{{
                                $t('common.sourcing_informations') }}</h1>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="sourcing.product_name" :label="$t('fields.product_name')" type="text"
                                :error="errors.product_name &&
                                    $t('errors.' + errors.product_name, {
                                        field: $t('fields.product_name'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="sourcing.quantity" :label="$t('fields.quantity')" type="number"
                                :error="errors.quantity &&
                                    $t('errors.' + errors.quantity, {
                                        field: $t('fields.quantity'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="sourcing.destination_country" max-height="200" option-label="name" option-value="value" :options="countries"
                                :label="$t('fields.destination_country')" type="text" :error="errors.destination_country &&
                                    $t('errors.' + errors.destination_country, {
                                        field: $t('fields.destination_country'),
                                    })
                                    ">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="sourcing.shipping_method" max-height="200" option-label="name" option-value="value" :options="shipping_methods"
                                :label="$t('fields.shipping_method')" type="text" :error="errors.shipping_method &&
                                    $t('errors.' + errors.shipping_method, {
                                        field: $t('fields.shipping_method'),
                                    })
                                    ">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div  class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:link-bold-duotone" v-model="sourcing.product_url" :label="$t('fields.product_url')" type="text"
                                :error="errors.product_url &&
                                    $t('errors.' + errors.product_url, {
                                        field: $t('fields.product_url'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <SourcingStatus v-model:sourcing="sourcing" :errors="errors" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField left-icon="solar:tag-price-bold-duotone" v-model="sourcing.cost_per_unit" :label="$t('fields.cost_per_unit')" type="number"
                                :error="errors.cost_per_unit &&
                                    $t('errors.' + errors.cost_per_unit, {
                                        field: $t('fields.cost_per_unit'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField left-icon="ic:twotone-local-shipping" v-model="sourcing.shipping_cost" :label="$t('fields.shipping_cost')" type="number"
                                :error="errors.shipping_cost &&
                                    $t('errors.' + errors.shipping_cost, {
                                        field: $t('fields.shipping_cost'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="hugeicons:money-add-02" v-model="sourcing.additional_fees" :label="$t('fields.additional_fees')" type="number"
                                :error="errors.additional_fees &&
                                    $t('errors.' + errors.additional_fees, {
                                        field: $t('fields.additional_fees'),
                                    })
                                    " />
                        </div>



                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sourcing.note" :label="$t('fields.note')" type="textarea"
                                :error="errors.note &&
                                    $t('errors.' + errors.note, {
                                        field: $t('fields.note'),
                                    })
                                    " />
                        </div>

                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]" intent="simple">{{
                        $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onCreate" class="tw-min-w-[120px]">{{
                        $t("buttons.create") }}</a-button>
                </div>
            </div>
        </div>
    </a-popup>

</template>

<script setup>
import { ref, defineEmits, watch, computed } from 'vue';
import { create } from '@/api/sourcings';
import { useI18n } from 'vue-i18n';
import useAlert from '@/composables/useAlert';
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import SourcingStatus from './partials/SourcingStatus.vue';
import { countries as shipping_countries, shipping_methods as shipping_ways } from '@/config/sourcing';

const i18n = useI18n()

const emit = defineEmits(['created']);

const visible = ref(false);
const sourcing = ref({
    status: 'new',
    shipping_method: 'not-selected',
    destination_country: 'not-selected',
    quantity: 0,
    shipping_cost: 0,
    cost_per_unit: 0,
    additional_fees: 0,
});

const countries = computed(() => shipping_countries.map(c => ({ ...c, name: i18n.t('countries.' + c.value) })))
const shipping_methods = computed(() => shipping_ways.map(c => ({ ...c, name: i18n.t('sourcing.shipping_methods.' + c.value) })))
const errors = ref({
    
})
const loading = ref(false)


watch(() => sourcing.value.customer_city, () => {
        sourcing.value.customer_area = null;
})


const onCreate = async () => {
    const data = { ...sourcing.value }

    loading.value = true
    create
    data
    await create(data)
        .then(
            res => {
                console.log(res)
                if (res.data.code == 'SUCCESS') {
                    // emit('update:visible', false)
                    useAlert(i18n.t('messages.created-successfully', { field: i18n.t('common.sourcing') }))
                    visible.value = false;
                    emit('created', res.data.sourcing)
                }
            },

            (err) => {
                errors.value = {}
                
                if (err?.response?.status == 422) {
                    for (let e in err.response.data.errors) {
                        errors.value[e] = err.response.data.errors[e][0];
                    }
                }
            }   
        )
        loading.value = false
}

const onCancel = () => {
    visible.value = false;
}


</script>

<style></style>