<template>
    <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
        <router-link :to="{ name: 'pages.dashboard.admin' }"
            class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2">
            <icon icon="solar:home-smile-bold-duotone" />
            <span class="tw-text-sm">{{
                $t("dashboard.sidebar.dashboard")
            }}</span>
        </router-link>
        <span class="tw-text-gray-300">/</span>
        <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2">
            <span class="tw-text-sm">{{ $t("dashboard.sidebar.sourcings") }}</span>
        </button>
    </div>
    <div class="tw-p-2">
        <div
            class="tw-h-full tw-w-full tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
            <div class="tw-justify-between tw-flex tw-items-center tw-h-[40px] tw-gap-2">

                <div class="tw-flex tw-items-center tw-gap-2">
                    <div class="tw-flex tw-items-center">
                        <FormField v-model="search" @keyup.enter="onSearch" input-dir="ltr"
                            placeholder="Sourcing ID..." left-icon="clarity:search-line" type="text"
                            class="tw-min-w-[120px]  ltr:!tw-rounded-none tw-peer"
                            input-class="ltr:tw-rounded-e-none ltr:tw-border-e-0 rtl:!tw-border-s-0 rtl:tw-rounded-e rtl:tw-border-e rtl:tw-rounded-s-none " />
                        <a-button @click="onSearch"
                            class=" tw-h-[40px] !tw-rounded-s-none tw-border-s tw-mt-0.5 !tw-border-b peer-focus-within:tw-border-primary-400 active:!tw-translate-y-0 active:tw-bg-primary-100"
                            icon="solar:arrow-right-line-duotone" intent="simple"></a-button>

                    </div>
                    <FormVueSelect v-model="options.per_page" :hide-label="true" :hide-errors="true" :searchable="false"
                        :options="[{ per_page: 5 }, { per_page: 10 }, { per_page: 20 }, { per_page: 50 }, { per_page: 100 }, { per_page: 250 }]"
                        option-label="per_page" option-value="per_page" :filterable="false" max-height="200"
                        class="tw-w-[120px]"></FormVueSelect>
                    <a-button v-if="false" @click="visible.filters = !visible.filters" class="tw-mt-0.5 tw-h-[40px]" intent="simple"
                        icon="solar:filter-bold-duotone"></a-button>
                </div>
                <CreateSourcingPopup @created="onCreated" />
            </div>

            <div class="tw-mt-5 tw-roundedx tw-overflow-hiddenx">
                <SourcingTable :loading="loading" :items="sourcings" :options="options" @next="onNext"
                    @previous="onPrevious" />
            </div>
        </div>

        
    </div>
</template>

<script setup>
import { ref, provide, watch } from "vue";
import { paginate } from "@/api/sourcings";
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import SourcingTable from "@/views/admin/sourcings/partials/table/SourcingTable";
import CreateSourcingPopup from '@/components/sourcings/CreateSourcingPopup'

const search = ref("");
const loading = ref(true);
const sourcings = ref([]);
const options = ref({
    per_page: 10,
});
const filters = ref({});

const visible = ref({
    filters: false
})

const active = ref({
})

async function fetchSourcings(page = 1) {
    sourcings.value = [];
    loading.value = true;
    await paginate({ per_page: options.value.per_page, page, search: search.value, filters: filters.value })
        .then(
            ({ data }) => {
                const { data: items, ...ops } = data.data;
                options.value = ops
                sourcings.value = items;
            },
            (error) => {
                console.log(error);
            }
        );
    loading.value = false;
}

watch(
    () => options.value.per_page,
    () => {
        fetchSourcings();
    }
)

const onUpdate = (sourcing) => {
    sourcings.value = sourcings.value.map((item) => item.id == sourcing.id ? sourcing : item);
}

const onSearch = () => {
    fetchSourcings(1)
}

const onFilter = () => {
    fetchSourcings(1)
} 
onFilter



const onNext = () => {
    fetchSourcings(options.value.current_page + 1)
}

const onPrevious = () => {
    fetchSourcings(options.value.current_page - 1)
}

const onCreated = sourcing => {
    sourcings.value.unshift(sourcing);
}

provide("updateSourcing", onUpdate);
provide("active", active);

fetchSourcings();
</script>

<style></style>
