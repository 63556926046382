import DashboardLayout from "@/layouts/dashboard/DashboardLayout";

import ads from "./ads"

export default [
  {
    path: "/marketer",
    component: DashboardLayout,
    meta: {
      middleware: ["auth"],
      roles: ["marketer"],
    },
    children: [
      ...ads
    ],
  },
];
