import {
    server
} from "@/api";


export const create = async (sourcing) => {
    return await server().post('api/sourcings', sourcing)
}

export const paginate = async (params) => {
    return await server().get('api/sourcings', { params });
}

export const update = async (id, sourcing) => {
    return await server().post(`api/sourcings/${id}`, sourcing);
}

export default {
    create,
    paginate,
    update
}