<template>
    <div>
        <a-popup :visible="props.visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[1200px] tw-overflow-hidden tw-h-fit dark:tw-bg-gray-900 tw-bg-white tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-5 tw-rounded">
                <div
                    class="tw-p-4 tw-text-lg tw-border-b tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 tw-border-gray-300 dark:tw-bg-gray-100 tw-bg-gray-50">
                    {{ $t("titles.time-tracking") }}
                </div>

                <div class="tw-p-5x tw-bg-white">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-2">
                        <div v-if="false"
                            class="tw-h-[400px]x tw-p-2 tw-bg-gray-50x tw-border-e tw-border-solid tw-overflow-hidden tw-border-gray-300 md:tw-col-span-5 tw-col-span-12 tw-roundedx">

                            <div role="button" v-for="d in daysOfWeek" :key="d" :class="[d.isSame(moment(), 'day') && '',
                            selectedDate.isSame(d, 'day') && 'tw-bg-primary-50 tw-ring tw-ring-primary-300 '
                            ]" @click="handleDateChange(d)"
                                class="tw-flex tw-items-center tw-justify-between tw-w-full tw-border-b tw-border-solid last:tw-border-b-0 tw-cursor-pointer hover:tw-bg-primary-50 tw-duration-200">
                                <div class="tw-flex tw-flex-col tw-p-2">
                                    <div class="tw-flex tw-items-center tw-gap-2">
                                        <div class="tw-text-gray-500 tw-text-sm">{{
                                            moment(d).locale($i18n.locale).format('ddd') }}</div>
                                        <div v-if="d.isSame(moment(), 'day')"
                                            class="tw-text-[10px] tw-text-white tw-bg-primary-500 tw-px-1 tw-rounded tw-pb-0.5x">
                                            {{ $t('common.today') }}</div>
                                    </div>
                                    <div class="tw-text-gray-800 tw-font-semibold">
                                        {{ moment(d).locale($i18n.locale).format('D MMM') }}
                                    </div>
                                </div>
                                <div class="tw-flex tw-flex-col tw-p-2">
                                    <div class="tw-text-gray-500 tw-text-sm">{{ $t("common.total") }}</div>
                                    <div class="tw-text-gray-800 tw-font-semibold">
                                        2h 30m
                                    </div>
                                </div>
                            </div>

                            <div
                                class="tw-w-full tw-bg-gray-200x tw-p-4 tw-mt-4x tw-flex tw-items-center tw-justify-between tw-gap-2">
                                <p class="tw-text-primary-500 tw-text-lg">{{ $t("common.total") }}</p>
                                <p class="tw-text-2xl tw-font-semibold">10h 45min</p>
                            </div>
                        </div>

                        <div v-if="!loading"
                            class="tw-col-span-12 md:tw-col-span-12 tw-p-2  tw-grid tw-grid-cols-7 tw-gap-2">
                            <button @click="handleDateChange(d.date)"
                                :class="[d.date.isSame(moment(selectedDate), 'day') && 'tw-bg-primary-50 tw-border-primary-500']"
                                class="tw-p-2 tw-border tw-border-solid tw-duration-200 hover:tw-bg-primary-50 tw-cursor-pointer tw-border-gray-200 tw-rounded"
                                v-for="d in daysOfWeek" :key="d">
                                <div>
                                    {{ moment(d.date).locale($i18n.locale).format('dddd DD MMM') }}
                                </div>
                                <p class="tw-text-lg tw-font-bold tw-text-primary-500 tw-mt-1">{{ d.minutes }}
                                </p>

                            </button>


                            <div class="tw-flex tw-flex-col tw-gap-2 tw-col-span-7">
                                <div v-if="!loadingChart">
                                    <UserTimeTrackingDayChart v-if="selectedDateData.length"  :key="selectedDate" :day="selectedDate" :data="selectedDateData" :item="props.item" />
                                    <div v-else class="tw-p-2 tw-h-[100px] tw-flex tw-items-center tw-justify-center">
                                        <p>{{ $t('messages.table-no-results') }}</p>
                                    </div>
                                </div>
                                
                                <div class="tw-h-[100px] tw-w-full tw-bg-gray-100 tw-p-2 tw-animate-pulse" v-if="loadingChart">
                                    <div class="tw-h-[50px] tw-bg-gray-200 tw-animate-pulse"></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="loading" class="tw-col-span-12 md:tw-col-span-12 tw-p-2 tw-ps-0  tw-h-[200px]">
                            <div
                                class="tw-h-full tw-w-full tw-p-5 tw-bg-gray-100 tw-rounded tw-animate-pulse tw-flex tw-items-end tw-gap-5 tw-border tw-border-solid tw-border-gray-200">
                                <div class="tw-h-1/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-5/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-1/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-5/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-1/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-5/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-1/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-2/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-3/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-4/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                                <div class="tw-h-5/6 tw-w-8 tw-p- tw-bg-gray-200 tw-animate-pulse"></div>
                            </div>
                        </div>

                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-border-gray-300 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button @click="onCancel" class="tw-min-w-[120px]" intent="simple">{{
                        $t("buttons.cancel") }}</a-button>
                </div>
            </div>
        </a-popup>
    </div>
</template>

<script setup>
import moment from "moment";
import UserTimeTrackingDayChart from "./UserTimeTrackingDayChart.vue";
import { defineProps, defineEmits, ref } from "vue";
import { getTrackedTime, getTrackedTimeByDays } from "@/api/users";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    item: {
        type: Object
    }
});

const emit = defineEmits(['update:visible']);

const loading = ref(false);
const loadingChart = ref(false);
const daysOfWeek = ref([]);
const selectedDate = ref(moment());
const selectedDateData = ref([]);

const onCancel = () => {
    emit('update:visible', false);
};


const generateDates = () => {
    const endOfWeek = moment(); // Today as the end
    const startOfWeek = moment().subtract(6, 'days'); // 7 days ago as the start

    let day = startOfWeek;
    const days = [];

    while (day <= endOfWeek) {
        days.push(day.clone()); // Clone to avoid mutation
        day = day.clone().add(1, 'day');
    }

    daysOfWeek.value = days;
    daysOfWeek.value.reverse()
}


generateDates()

const handleDateChange = (date) => {
    selectedDate.value = date;
    getData();
}

const getData = async () => {
    loadingChart.value = true;
    await getTrackedTime(props.item.id, selectedDate.value.format('YYYY-MM-DD'))
    .then(
        res => {
            if(res.data.code == 'SUCCESS') {
                selectedDateData.value = res.data.data;
            }
        }
    )
    loadingChart.value = false;
}

const getInitialData = async () => {
    loading.value = true;
    await getTrackedTimeByDays(props.item.id)
        .then(
            res => {
                if (res.data.code == 'SUCCESS') {
                    // daysData.value = res.data.data;
                    daysOfWeek.value = res.data.data.map(d => {

                        let formated = "0h 0min";
                        let minutes = parseInt(d.minutes);

                        if(minutes > 0) {
                            const hours = Math.floor(minutes / 60);
                            const remainingMinutes = minutes % 60;
                            formated = `${hours}h ${remainingMinutes}min`;
                        }

                        return ({ minutes: formated, date: moment(d.date) })
                    }
                    );

                }
            }
        )
    loading.value = false;
}

getData();
getInitialData();


</script>

<style></style>
