<template>
    <div class="tw-rounded tw-overflow-auto tw-border tw-border-solid tw-border-gray-100">
        <table class="tw-min-w-full tw-leading-normal">
            <thead class="">
                <tr>
                    <th v-for="c in columns" :key="c.name" :class="c.classes"
                        class="tw-px-5 last:tw-border-e-0 tw-border-e tw-py-3 tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-text-[10px] tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider">
                        {{ $t(c.label) }}
                    </th>
                </tr>
            </thead>

            <tbody v-if="props.loading">
                <tr class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50"
                    v-for="i in 10" :key="i">
                    <td v-for="c in columns" :key="c.name" class="tw-px-2 tw-py-3">
                        <div
                            class="tw-w-full tw-py-3 tw-bg-gray-50 group-even:tw-bg-white  tw-rounded tw-min-w-[30px] tw-animate-pulse">

                        </div>
                    </td>
                </tr>
            </tbody>


            <tbody v-else>
                <template v-if="items.length > 0">
                    <AdTableRow v-for="item in items" :key="item.id + ' ' + item.updated_at" :item="item" />
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="columns.length">
                            <div
                                class="tw-p-5 tw-bg-white tw-min-h-[250px]  tw-text-gray-600 tw-flex tw-items-center tw-justify-center tw-text-lg">
                                <p>{{ $t('messages.table-no-results') }}</p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>

    <div class="tw-my-4"></div>

    <TablePagination v-if="items.length > 0" :has-next="!!options.next_page_url" :has-previous="!!options.prev_page_url" @previous="emit('previous')" @next="emit('next')" />
</template>

<script setup>
import { ref, defineProps, toRef, defineEmits } from 'vue'
import TablePagination from '@/components/table/TablePagination'
import AdTableRow from './AdTableRow'

const props = defineProps(['loading', 'data', 'items', 'options']);
const items = toRef(props, 'items')
const emit = defineEmits(['next', 'previous'])

const columns = ref([
    { name: 'id', label: 'fields.id', classes: ['tw-w-[30px]'] },
    { name: 'product', label: 'fields.product', classes: [] },
    { name: 'platform', label: 'fields.platform', classes: ['tw-w-[70px]'] },
    { name: 'spend', label: 'fields.spend', classes: ['tw-w-[70px]'] },
    { name: 'started_at', label: 'fields.started_at', classes: ['tw-w-[150px]'] },
    { name: 'stopped_at', label: 'fields.stopped_at', classes: ['tw-w-[150px]'] },
    { name: 'actions', label: 'fields.actions', classes: ['tw-w-[100px]'] },
])
</script>

<style></style>