export const countries = [
    // add select country empry field
    { name: 'Select Country', value: 'not-selected' },
    { name: 'Libya', value: 'LY' },
];

export const shipping_methods = [
    // add select country empry field
    { name: 'Select Country', value: 'not-selected' },
    { name: 'Air', value: 'air' },
    { name: 'Sea', value: 'sea' },
    { name: 'Land', value: 'land' }
];

export const statuses = [
    { name: 'sourcing.statuses.new', value: 'new', color: 'tw-bg-gray-100 tw-text-black' },
    { name: 'sourcing.statuses.pending', value: 'pending', color: 'tw-bg-gray-500 tw-text-white' },
    { name: 'sourcing.statuses.quoting', value: 'quoting', color: 'tw-bg-orange-500 tw-text-white' },
    { name: 'sourcing.statuses.in-progress', value: 'in-progress', color: 'tw-bg-blue-500 tw-text-white' },
    { name: 'sourcing.statuses.packing', value: 'packing', color: 'tw-bg-indigo-500 tw-text-white' },
    { name: 'sourcing.statuses.shipped', value: 'shipped', color: 'tw-bg-purple-500 tw-text-white' },
    { name: 'sourcing.statuses.completed', value: 'completed', color: 'tw-bg-emerald-500 tw-text-white' },
    { name: 'sourcing.statuses.cancelled', value: 'cancelled', color: 'tw-bg-red-500 tw-text-white' },
];