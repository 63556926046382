import DashboardLayout from "@/layouts/dashboard/DashboardLayout";

import products from "./products";
import users from "./users";
import orders from "./orders";
import sheets from "./sheets";
import ads from "./ads";
import sourcings from "./sourcings";

export default [
  {
    path: "/admin",
    component: DashboardLayout,
    meta: {
      middleware: ["auth"],
      roles: ["admin"],
    },
    children: [
      ...products,
      ...users,
      ...orders,
      ...sheets,
      ...ads,
      ...sourcings
    ],
  },
];
