<template>
    <div>
        <FormVueSelect :searchable="false" v-model="sourcing.status" max-height="200" :options="statuses"
            option-value="value" :label="$t('fields.status')" :error="errors.status &&
                $t('errors.' + errors.status, {
                    field: $t('fields.status'),
                })
                ">
            <template v-slot:option="{ option }">
                <p :class="[option.color]" class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    $t(option.name) }}</p>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { statuses as sourcing_statuses } from '@/config/sourcing';
import { defineProps, defineEmits, computed, toRef } from 'vue';
import { useI18n } from "vue-i18n";

const i18n = useI18n();

const statuses = computed(() => sourcing_statuses.map(c => ({ ...c, label: i18n.t(c.name) })))
const props = defineProps(['sourcing', 'errors'])
const emit = defineEmits(['update:sourcing'])
const errors = toRef(props, 'errors')

const sourcing = computed({
    get() {
        return props.sourcing
    },
    set(value) {
        emit('update:sourcing', value)
    }
})
</script>

<style></style>