<template>
    <div class="tw-w-full tw-flex tw-text-center tw-justify-end tw-gap-2">
        <table-update-action :item="props.item" />
        <table-history-action v-if="false" :item="props.item" />
        <a-action-button v-if="false" icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>
    </div>
</template>

<script setup>
import TableUpdateAction from "./actions/TableUpdateAction";
import TableHistoryAction from "./actions/TableHistoryAction";
import { defineProps } from "vue";

const props = defineProps({
    item: {
        required: true
    }
})

</script>

<style>

</style>