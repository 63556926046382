<template>
  <div class="tw-rounded tw-border tw-border-solid tw-border-gray-100 tw-overflow-auto">
    <table class="tw-min-w-full tw-leading-normal tw-w-full">
      <thead class="">
        <tr>
          <th v-for="c in columns" :key="c.name" :class="c.classes"
            class="tw-px-5 tw-text-[10px] last:tw-border-e-0 tw-whitespace-nowrap tw-border-e tw-py-3 tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider">
            {{ $t(c.label) }}
          </th>
        </tr>
      </thead>

      <tbody v-if="props.loading">
        <tr class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50"
          v-for="i in 10" :key="i">
          <td v-for="c in columns" :key="c.name" class="tw-px-2 tw-py-3">
            <div
              class="tw-w-full tw-py-3 tw-bg-gray-50 group-even:tw-bg-white  tw-rounded tw-min-w-[30px] tw-animate-pulse">

            </div>
          </td>
        </tr>
      </tbody>


      <tbody v-else>
        <template v-if="props.items.length > 0">
          <SourcingTableRow v-for="item in items" :key="item.id + item.updated_at" :item="item" />
        </template>
        <template v-else>
          <tr>
            <td :colspan="columns.length">
              <div
                class="tw-p-5 tw-bg-white tw-min-h-[250px]  tw-text-gray-600 tw-flex tw-items-center tw-justify-center tw-text-lg">
                <p>{{ $t('messages.table-no-results') }}</p>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

  </div>

  <div class="tw-my-4"></div>

  <TablePagination v-if="props.items.length > 0" :has-next="!!options.next_page_url"
    :has-previous="!!options.prev_page_url" @previous="emit('previous')" @next="emit('next')" />
</template>

<script setup>
import { computed, defineProps, defineEmits, inject } from 'vue'
import TablePagination from '@/components/table/TablePagination'
import SourcingTableRow from './SourcingTableRow'

const props = defineProps(['loading', 'data', 'items', 'options']);
const emit = defineEmits(['next', 'previous'])
const active = inject('active')
active

const columns = computed(() => {
  let cols = [
    { name: 'id', label: 'fields.id', classes: ['tw-w-[30px]'] },
    { name: 'product_name', label: 'fields.product_name', classes: [''] },
    { name: 'quantity', label: 'fields.quantity', classes: [''] },
    { name: 'destination_country', label: 'fields.destination_country', classes: [''] },
    { name: 'note', label: 'fields.note', classes: [''] },
    { name: 'shipping_method', label: 'fields.shipping_method', classes: [''] },
    { name: 'status', label: 'fields.status', classes: [''] },
    { name: 'cost_per_unit', label: 'fields.cost_per_unit', classes: [''] },
    { name: 'shipping_cost', label: 'fields.shipping_cost', classes: [''] },
    { name: 'additional_fees', label: 'fields.additional_fees', classes: [''] },
    { name: 'total_cost', label: 'fields.total_cost', classes: [''] },
    { name: 'created_at', label: 'fields.created_at', classes: [''] },
    { name: 'actions', label: 'fields.actions', classes: [''] },
  ]


  return cols;

})
</script>

<style></style>
