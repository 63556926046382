<template>
    <a-popup :visible="visible" @cancel="onCancel">
        <div
            class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
            <div>
                <div
                    class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold dark:tw-text-gray-700 tw-text-gray-500 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <span>{{ $t("common.sourcing_request") }}</span>
                    <span class="tw-text-primary-500">{{ sourcing.id }}</span>
                </div>

                <div class="tw-p-5 tw-bg-white tw-flex-1">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        <div class="tw-col-span-12 md:tw-col-span-12 tw-pb-4">
                            <h1 class="tw-ps-4 tw-border-s-2 tw-border-solid tw-border-primary-500">{{
                                $t('common.sourcing_informations') }}</h1>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="sourcing.product_name" :label="$t('fields.product_name')" type="text"
                                :error="errors.product_name && $t('errors.' + errors.product_name, {
                                    field: $t('fields.product_name'),
                                })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="sourcing.quantity" :label="$t('fields.quantity')" type="number" :error="errors.quantity && $t('errors.' + errors.quantity, {
                                field: $t('fields.quantity'),
                            })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="sourcing.destination_country" max-height="200" option-label="name"
                                option-value="value" :options="countries" :label="$t('fields.destination_country')"
                                type="text" :error="errors.destination_country && $t('errors.' + errors.destination_country, {
                                    field: $t('fields.destination_country'),
                                })">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="sourcing.shipping_method" max-height="200" option-label="name"
                                option-value="value" :options="shipping_methods" :label="$t('fields.shipping_method')"
                                type="text" :error="errors.shipping_method && $t('errors.' + errors.shipping_method, {
                                    field: $t('fields.shipping_method'),
                                })">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:link-bold-duotone" v-model="sourcing.product_url"
                                :label="$t('fields.product_url')" type="text" :error="errors.product_url && $t('errors.' + errors.product_url, {
                                    field: $t('fields.product_url'),
                                })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <SourcingStatus v-model:sourcing="sourcing" :errors="errors" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField left-icon="solar:tag-price-bold-duotone" v-model="sourcing.cost_per_unit"
                                :label="$t('fields.cost_per_unit')" type="number" :error="errors.cost_per_unit && $t('errors.' + errors.cost_per_unit, {
                                    field: $t('fields.cost_per_unit'),
                                })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField left-icon="ic:twotone-local-shipping" v-model="sourcing.shipping_cost"
                                :label="$t('fields.shipping_cost')" type="number" :error="errors.shipping_cost && $t('errors.' + errors.shipping_cost, {
                                    field: $t('fields.shipping_cost'),
                                })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="hugeicons:money-add-02" v-model="sourcing.additional_fees"
                                :label="$t('fields.additional_fees')" type="number" :error="errors.additional_fees && $t('errors.' + errors.additional_fees, {
                                    field: $t('fields.additional_fees'),
                                })" />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sourcing.note" :label="$t('fields.note')" type="textarea" :error="errors.note && $t('errors.' + errors.note, {
                                field: $t('fields.note'),
                            })" />
                        </div>
                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]" intent="simple">{{
                        $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onUpdate" class="tw-min-w-[120px]">{{
                        $t("buttons.update") }}</a-button>
                </div>
            </div>
        </div>
    </a-popup>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from 'vue';
import { clone } from '@/utils/methods';
import { update } from '@/api/sourcings';
import { useI18n } from 'vue-i18n';
import useAlert from '@/composables/useAlert';
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import SourcingStatus from './partials/SourcingStatus.vue';
import { countries as shipping_countries, shipping_methods as shipping_ways } from '@/config/sourcing';

const i18n = useI18n()

const emit = defineEmits(['update:visible', 'update']);
const props = defineProps({
    item: {
        required: true
    },
    visible: {
        required: true,
        default: false
    }
});

const sourcing = ref(clone(props.item));
const originalSourcing = ref(clone(props.item));
const countries = computed(() => shipping_countries.map(c => ({ ...c, name: i18n.t('countries.' + c.value) })))
const shipping_methods = computed(() => shipping_ways.map(c => ({ ...c, name: i18n.t('sourcing.shipping_methods.' + c.value) })))
const errors = ref({})
const changed = ref([])
const loading = ref(false)

watch(() => props.item, (newValue) => {
    sourcing.value = clone(newValue)
}, { deep: true })

const onUpdate = async () => {
    const fields = getChangedFields()
    const data = { ...sourcing.value, fields }

    loading.value = true
    await update(props.item.id, data)
        .then(
            res => {
                if (res.data.code == 'SUCCESS') {
                    emit('update:visible', false)
                    emit('update', res.data.sourcing)
                    useAlert(i18n.t('messages.updated-successfully', { field: i18n.t('common.sourcing') }))
                }
            },
            (err) => {
                errors.value = {}
                if (err?.response?.status == 422) {
                    for (let e in err.response.data.errors) {
                        errors.value[e] = err.response.data.errors[e][0];
                    }
                }
            }
        )
    loading.value = false
}

const onCancel = () => {
    emit('update:visible', false)
}

const getChangedFields = () => {
    changed.value = []
    for (const key in sourcing.value) {
        if (sourcing.value[key] !== originalSourcing.value[key]) {
            changed.value.push(key)
        }
    }
    return changed.value
}
</script>

<style></style>