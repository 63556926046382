import AdListView from "@/views/marketer/ads/AdListView";

export default [
  {
    name: "pages.marketer.ads",
    path: "ads",
    component: AdListView
  },
  
];
