<template>
    <div>
        <a-action-button @click="visible = true" icon="solar:pen-bold-duotone" intent="warning"></a-action-button>

        <transition>
            <div v-if="container">
                <UpdateSourcingPopup v-model:visible="visible" :item="props.item" @update="onUpdate" />
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, watch, defineProps, inject } from 'vue';
import UpdateSourcingPopup from '@/components/sourcings/UpdateSourcingPopup';

const updateSourcing = inject('updateSourcing')
const visible = ref(false);
const container = ref(false);
const props = defineProps({
    item: {
        required: true
    }
})

const onUpdate = (sourcing) => {
    console.log('updating')
    updateSourcing(sourcing)
}

watch(() => visible.value, (newValue) => {

    if (!newValue) {
        setTimeout(() => container.value = false, 300)
    }

    if (newValue) {
        container.value = true
    }
});

</script>

<style></style>