<template>
    <div>
        <a-button @click="visible = true" class="tw-hidden md:tw-flex" left-icon="heroicons:plus-16-solid">{{
            $t("buttons.create") }}</a-button>
        <a-button @click="visible = true" class="md:tw-hidden" icon="heroicons:plus-16-solid"></a-button>
        <a-popup :visible="visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-overflow-hidden tw-h-fit dark:tw-bg-gray-900 tw-bg-white tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-5 tw-rounded">
                <div
                    class="tw-p-4 tw-text-lg tw-border-b tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    {{ $t("titles.create-new-item") }}
                </div>

                <div class="tw-p-5 tw-bg-white">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormVueSelect left-icon="solar:devices-bold-duotone" v-model="ad.platform" option-value="value" :label="$t('fields.platform')" type="select" :options="platforms" :error="errors.platform &&
                                $t('errors.' + errors.platform, {
                                    field: $t('fields.platform'),
                                })
                                " />
                        </div>
                        
                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormVueSelect left-icon="solar:box-bold-duotone" v-model="ad.product_id" option-value="id" :label="$t('fields.product')" type="select" :options="products" :error="errors.product_id &&
                                $t('errors.' + errors.product_id, {
                                    field: $t('fields.product'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:dollar-bold-duotone" v-model="ad.spend" :label="$t('fields.spend')" type="number" :error="errors.spend &&
                                $t('errors.' + errors.spend, {
                                    field: $t('fields.spend'),
                                })
                                " />
                        </div>


                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:calendar-bold-duotone" v-model="ad.started_at" :label="$t('fields.started_at')" type="date" :error="errors.started_at &&
                                $t('errors.' + errors.started_at, {
                                    field: $t('fields.started_at'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:calendar-bold-duotone" v-model="ad.stopped_at" :label="$t('fields.stopped_at')" type="date" :error="errors.stopped_at &&
                                $t('errors.' + errors.stopped_at, {
                                    field: $t('fields.stopped_at'),
                                })
                                " />
                        </div>


                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-between tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    
                    <div>

                    </div>
                    <div class="tw-flex tw-gap-4 tw-items-center">
                        <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]"
                        intent="simple">{{
                            $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onCreate"
                        class="tw-min-w-[120px]">{{
                            $t("buttons.create") }}</a-button>
                    </div>
                </div>
            </div>
        </a-popup>
    </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import { create } from "@/api/ads";
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";
import { useStateStore } from "@/stores/app/state";


const i18n = useI18n()
const stateStore = useStateStore();

const platforms = [{label: 'Facebook', value: 'facebook'}, {label: 'TikTok', value: 'tiktok'}];
const products = computed(() => [...stateStore.products.map(p => ({ ...p, label: p.name }))]);
const visible = ref(false);
const loading = ref(false);
const ad = ref({ product_id: null, platform: 'facebook' });
const errors = ref({});
const onCreated = inject('onCreated')

const onCancel = () => {
    ad.value = { product_id: null, platform: 'facebook' };
    errors.value = {}
    visible.value = false;
};

const onCreate = async () => {
    loading.value = true;
    errors.value = {}
    return await create(ad.value)
        .then(
            r => {
                if (r.data.code == 'SUCCESS') {
                    useAlert(i18n.t('messages.created-successfully', { field: i18n.t('fields.ad') }))
                    onCreated(r.data.ad);
                    onCancel();
                }
            },
            error => {
                if (error.response.status == 422) {
                    for (let e in error.response.data.errors) {
                        errors.value[e] = error.response.data.errors[e][0];
                    }
                }
            }
        )
        .finally(
            () => loading.value = false
        )
};
</script>

<style></style>
