<template>
    <div class="tw-rounded tw-border tw-border-solid tw-border-gray-100 tw-overflow-auto">
        <table class="tw-min-w-full tw-leading-normal tw-w-full">
            <thead class="">
                <tr>
                    <th v-for="c in columns" :key="c.name" :class="c.classes"
                        class=" tw-text-[10px] last:tw-border-e-0 tw-whitespace-nowrap tw-border-e tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider">
                        <div @click="onColumnClick(c)" :role="c.sortable ? 'button' : 'div'" class="tw-flex tw-items-center tw-gap-4 tw-justify-between tw-py-3 tw-px-5" :class="[
                            c.sortable && 'hover:tw-bg-gray-200/20 tw-duration-200'
                        ]">
                            <span>{{ $t(c.label) }}</span>
                            <icon v-if="c.sortable"
                                :class="[
                                    sort.find(cl => cl.field == c.name) ? 'tw-text-gray-900' : 'tw-text-gray-400',
                                    sort.find(cl => cl.field == c.name)?.direction == 'desc' && 'tw-rotate-180'
                                ]"
                            icon="solar:sort-line-duotone" class="tw-text-gray-400 tw-w-4 tw-h-4" />

                        </div>
                    </th>
                </tr>
            </thead>

            <tbody v-if="props.loading">
                <tr class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50"
                    v-for="i in 10" :key="i">
                    <td v-for="c in columns" :key="c.name" class="tw-px-2 tw-py-3">
                        <div
                            class="tw-w-full tw-py-3 tw-bg-gray-50 group-even:tw-bg-white  tw-rounded tw-min-w-[30px] tw-animate-pulse">

                        </div>
                    </td>
                </tr>
            </tbody>


            <tbody v-else>
                <template v-if="items.length > 0">
                    <OrderTableRow v-for="item in items" :key="item.id + item.updated_at" :item="item" />
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="columns.length">
                            <div
                                class="tw-p-5 tw-bg-white tw-min-h-[250px]  tw-text-gray-600 tw-flex tw-items-center tw-justify-center tw-text-lg">
                                <p>{{ $t('messages.table-no-results') }}</p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>

    <div class="tw-my-4"></div>

    <TablePagination v-if="items.length > 0" :has-next="!!options.next_page_url" :has-previous="!!options.prev_page_url" @previous="emit('previous')" @next="emit('next')" />
</template>

<script setup>
import { ref, defineProps, toRef, defineEmits } from 'vue'
import TablePagination from '@/components/table/TablePagination'
import OrderTableRow from './OrderTableRow'

const props = defineProps(['loading', 'data', 'items', 'options']);
const items = toRef(props, 'items')
const emit = defineEmits(['next', 'previous'])
const sort = ref([]);

const columns = ref([
    { name: 'id', label: 'fields.id', classes: ['tw-w-[30px]'] },
    { name: 'customer_name', label: 'fields.customer_name', classes: ['tw-w-[160px]'] },
    { name: 'customer_phone', label: 'fields.customer_phone', classes: ['tw-w-[120px]'] },
    { name: 'customer_city', label: 'fields.customer_city', classes: ['tw-max-w-[120px]'] },
    { name: 'order_summary', label: 'fields.order_summary', classes: [ 'tw-max-w-[120px]' ] },
    { name: 'price', label: 'fields.price', classes: [ 'tw-w-[100px]' ] },
    { name: 'calls', label: 'fields.calls', classes: [ 'tw-w-[70px]' ] },
    { name: 'followup_status', label: 'fields.followup_status', classes: ['tw-w-[120px]'] },
    { name: 'delivery_id', label: 'fields.delivery_id', classes: [ 'tw-w-[120px]' ] },
    { name: 'delivery_status', label: 'fields.delivery_status', classes: ['tw-w-[120px]'] },
    { name: 'reconfirmed_at', label: 'fields.reconfirmed_at', sortable: true, classes: ['tw-w-[100px]'] },
    { name: 'actions', label: 'fields.actions', classes: ['tw-w-[100px]'] },
])


const onColumnClick = (column) => {
    if (column.sortable) {
        let sortable = sort.value.find(s => s.field === column.name)

        if(sortable) {
            if(sortable.direction === 'asc') {
                sort.value = sort.value.map(s => s.field == column.name ? { ...s, direction: 'desc' } : s)
            } else {
                sort.value = sort.value.filter(s => s.field !== column.name)
            }
        } else {
            sort.value.push({ field: column.name, direction: 'asc' })
        }

        emit('sort', sort.value)
    }

}
</script>

<style></style>