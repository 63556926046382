<template>
    <div class="tw-w-full tw-flex tw-text-center tw-justify-end tw-gap-2 tw-px-2">
        <table-update-action :item="props.item" />
        <table-history-action :item="props.item" />
        <a-action-button v-if="false" icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>
        <a-action-button @click="onCall($user.role)" :title="$t('buttons.call')" as="a" :href="'tel:' + item.customer_phone" icon="solar:phone-bold-duotone" intent="info"></a-action-button>
    </div>
</template>

<script setup>
import TableUpdateAction from "./actions/TableUpdateAction";
import TableHistoryAction from "./actions/TableHistoryAction";
import { defineProps } from "vue";
import { call } from '@/api/orders';

const props = defineProps({
    item: {
        required: true
    }
})

const onCall = (role) => {
    call(props.item.id, role == 'admin' ? 'agent' : role)
    .then(
        res => {
            console.log(res.data)
        }
    )
}

</script>

<style>

</style>