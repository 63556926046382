<template>
    <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
        <router-link :to="{ name: 'pages.dashboard.admin' }"
            class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2">
            <icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard")
                }}</span>
        </router-link>
        <span class="tw-text-gray-300">/</span>
        <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span
                class="tw-text-sm">{{
                    $t("dashboard.sidebar.ads") }}</span></button>
    </div>
    <div class="tw-p-2">
        <div
            class="tw-h-full tw-w-full tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
            <div class="tw-justify-between tw-flex tw-items-center tw-h-[40px] tw-gap-2">
                <FormField v-model="search" input-dir="ltr" placeholder="ID, Name..." left-icon="clarity:search-line"
                    type="text" class="tw-min-w-[100px]" />

                <ad-create-popup />
            </div>
            <div class="tw-mt-5 tw-roundedx tw-overflow-hiddenx">
                <AdTable :loading="loading" :items="ads" :options="options" @next="onNext" @previous="onPrevious" />
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import { paginate } from '@/api/ads';
import FormField from "@/components/form/FormField";
import AdTable from '@/views/marketer/ads/partials/table/AdTable';
import AdCreatePopup from '@/views/marketer/ads/partials/AdCreatePopup';

const search = ref('')
const loading = ref(true)
const ads = ref([]);
const options = ref({});

async function fetchAds(page = 1) {
    loading.value = true;
    ads.value = [];
    await paginate({ per_page: 10, page })
        .then(
            ({ data }) => {
                const { data: items, ...ops } = data.data;
                options.value = ops
                ads.value = items;
            },
            error => {
                console.log(error)
            }
        )
    loading.value = false
}

const onNext = () => {
    fetchAds(options.value.current_page + 1)
}

const onPrevious = () => {
    fetchAds(options.value.current_page - 1)
}

const onCreated = (ad) => {
    ads.value.unshift(ad)
}

const onUpdated = (ad) => {
    ads.value = ads.value.map(u => u.id == ad.id ? ad : u)
}

const onDeleted = (id) => {
    ads.value = ads.value.filter(u => u.id != id)
}

provide('onCreated', onCreated)
provide('onUpdated', onUpdated)
provide('onDeleted', onDeleted)

fetchAds()
</script>

<style></style>