import SourcingListView from "@/views/admin/sourcings/SourcingListView";

export default [
  {
    name: "pages.admin.sourcings",
    path: "sourcing",
    component: SourcingListView
  },
  
];
