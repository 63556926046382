<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-max-w-[120px] tw-truncate">{{ item.product_name }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p>{{ item.quantity }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p>{{ $t('countries.' + item.destination_country) }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-max-w-[120px] tw-truncate">{{ item.note }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-max-w-[120px] tw-truncate">{{ $t('sourcing.shipping_methods.' + item.shipping_method) }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[100px] tw-text-xs tw-rounded tw-w-fit tw-px-4 tw-py-1 tw-mx-auto " :class="[status.color]" v-if="status?.name">
                {{ $t(status.name) }}
            </p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-0.5 tw-font-bold tw-text-sky-500">
                <p>$</p>
                <p>{{ item.cost_per_unit }}</p>
            </div>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-0.5 tw-font-bold tw-text-purple-500">
                <p>$</p>
                <p>{{ item.shipping_cost }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-0.5 tw-font-bold tw-text-gray-600">
                <p>$</p>
                <p>{{ item.additional_fees }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-items-center tw-gap-0.5 tw-font-bold tw-text-emerald-500">
                <p>$</p>
                <p>{{ totalCost }}</p>
            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div @mouseenter="item.hovering = true" @mouseleave="item.hovering = false" class="tw-flex tw-items-center tw-gap-1 tw-whitespace-nowrap">
                <p v-if="item.hovering">{{ item.created_at.split('T')[0] }}</p>
                <p v-else>{{ createdAt }}</p>
            </div>
        </td>

        <td class="tw-px-5">
            <SourcingTableActions :item="item" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef, computed } from "vue";
import SourcingTableActions from "./SourcingTableActions";
import { getSourcingStatus } from "@/utils/status";
import moment from 'moment'
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const props = defineProps(["item"]);
const item = toRef(props, "item");
const status = getSourcingStatus(item.value.status)

const createdAt = computed(() => {
    return moment(item.value.created_at).locale(i18n.locale.value).fromNow()
})

const totalCost = computed(() => {
    return ((item.value.cost_per_unit ?? 0) * (item.value.quantity ?? 0)) + (item.value.shipping_cost ?? 0) + (item.value.additional_fees ?? 0)
})
</script>

<style></style>
