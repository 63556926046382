<template>
    <div :class="[visible ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]']"
        class="tw-grid tw-duration-300 tw-transition-all tw-pt-2">
        <div class="tw-overflow-hidden tw-col-span-1">
            <div class="tw-p-4 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-2 lg:tw-grid-cols-4">
                <ConfirmationFilter v-if="['admin', 'agent'].includes($user.role)" />
                <ReconfirmationFilter v-if="['admin', 'followup'].includes($user.role)" />
                <DeliveryStatusFilter />
                <ProductFilter />
                <DeliveryFilter />
                <DeliveredAtFilter v-if="['admin'].includes($user.role)" />
                <DroppedAtFilter v-if="['admin'].includes($user.role)" />
                <TreatedAtFilter v-if="['admin'].includes($user.role)" />

                <div class="tw-col-span-1 md:tw-col-span-2 lg:tw-col-span-4 tw-flex tw-items-center tw-justify-end">
                    <div class="tw-w-fit tw-flex tw-items-center tw-gap-2" >
                        <a-button @click="onFilter" intent="primary" class="tw-flex-1">{{ $t('buttons.filter')
                        }}</a-button>
                    <a-action-button @click="onClearFilter" intent="simple" icon="clarity:filter-off-line"
                        class="tw-h-[40px] tw-w-[40px] tw-border-b-2"></a-action-button>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    <div v-if="false">
        <transition class="tw-duration-200" enter-from-class="rtl:-tw-translate-x-full ltr:tw-translate-x-full"
            leave-to-class="rtl:-tw-translate-x-full ltr:tw-translate-x-full" duration="200">

            <div v-if="visible" :class="[$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0']"
                class="tw-fixed tw-top-0 tw-h-full tw-z-[911] tw-overflow-auto tw-translate-x-0 md:tw-w-[400px] tw-w-full tw-bg-white tw-border-s tw-border-solid tw-border-gray-300">

                <div
                    class="tw-p-2 tw-bg-gray-50 tw-flex tw-justify-between tw-items-center tw-border-b tw-border-solid tw-border-gray-200">
                    <p>{{ $t('common.advanced-search') }}</p>
                    <button @click="visible = false"
                        class="tw-p-1 tw-bg-white tw-w-fit tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-duration-200 hover:tw-bg-gray-100 tw-cursor-pointer tw-flex tw-items-center tw-justify-center">
                        <icon icon="eva:close-fill" class="tw-text-xl tw-text-gray-500" />
                    </button>
                </div>

                <div
                    class="tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-h-full tw-max-h-[calc(100vh-110px)] tw-overflow-auto">
                    <ConfirmationFilter v-if="['admin', 'agent'].includes($user.role)" />
                    <ReconfirmationFilter v-if="['admin', 'followup'].includes($user.role)" />

                    <DeliveryStatusFilter />
                    <ProductFilter />
                    <DeliveryFilter />
                    <DeliveredAtFilter v-if="['admin'].includes($user.role)" />
                    <DroppedAtFilter v-if="['admin'].includes($user.role)" />
                    <TreatedAtFilter v-if="['admin'].includes($user.role)" />
                </div>

                <div
                    class="tw-sticky tw-top-full tw-right-0 tw-p-2 tw-bg-white tw-w-full tw-flex tw-border-t tw-items-center tw-gap-2 tw-border-solid tw-border-gray-300">
                    <a-button @click="onFilter" intent="primary" class="tw-flex-1">{{ $t('buttons.filter') }}</a-button>
                    <a-action-button @click="onClearFilter" intent="simple" icon="clarity:filter-off-line"
                        class="tw-h-[40px] tw-w-[40px] tw-border-b-2"></a-action-button>
                </div>
            </div>
        </transition>

        <transition class="tw-duration-200" enter-from-class="tw-opacity-0" leave-to-class="tw-opacity-0"
            duration="200">
            <div @click="visible = false" v-if="visible"
                class="tw-h-screen tw-w-full tw-bg-black/50 tw-backdrop-blurx tw-fixed tw-top-0 tw-right-0 tw-z-[910]">
            </div>

        </transition>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, provide, toRef } from 'vue';
import ConfirmationFilter from './partials/ConfirmationFilter';
import ReconfirmationFilter from './partials/ReconfirmationFilter';
import DeliveryStatusFilter from './partials/DeliveryStatusFilter';
import DeliveryFilter from './partials/DeliveryFilter';
import ProductFilter from './partials/ProductFilter';
import DeliveredAtFilter from './partials/DeliveredAtFilter';
import DroppedAtFilter from './partials/DroppedAtFilter';
import TreatedAtFilter from './partials/TreatedAtFilter';

const props = defineProps(['filters', 'visible']);
const emit = defineEmits(['update:filters', 'update:visible', 'filter']);
const filters = toRef(props, 'filters');

provide('filters', filters);
provide('update:filters', v => {
    emit('update:filters', v)
});

const visible = computed({
    get: () => props.visible,
    set: v => emit('update:visible', v)
})

const onFilter = () => {
    visible.value = false;
    emit('filter');
}

const onClearFilter = () => {
    visible.value = false;
    emit('update:filters', {})
    emit('filter');
}

</script>

<style></style>